import { Component, Input, inject } from '@angular/core'
import { UseFallbackImageDirective } from '@core/directives/use-fallback-image.directive'
import { AlertService } from '@core/services/alert.service'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { ChatRoom } from '@modules/chat/models/chat.model'
import { ChatImagePipe } from '@modules/chat/pipes/chat-image.pipe'
import { ChatNamePipe } from '@modules/chat/pipes/chat-name.pipe'
import { ChatService } from '@modules/chat/services/chat.service'

const MenusMap = {
    Archive: 'Archive',
    Unarchive: 'Unarchive',
    Delete: 'Delete',
} as const

@Component({
    selector: 'app-chatroom',
    standalone: true,
    imports: [ChatNamePipe, ChatImagePipe, UseFallbackImageDirective],
    templateUrl: './chatroom.component.html',
    styleUrl: './chatroom.component.scss',
})
export class ChatroomComponent {
    @Input() chatRooms: ChatRoom[] = []

    private alertService = inject(AlertService)
    chatService = inject(ChatService)
    authStateService = inject(AuthStateService)

    menusMap = MenusMap
    menus = Object.keys(MenusMap)
    dropdownOpenStates: { [key: string]: boolean } = {}

    selectChat(chatRoom: ChatRoom) {
        this.chatService.switchRoom(chatRoom)
    }

    toggleDropdown(chatId: string) {
        for (const id of Object.keys(this.dropdownOpenStates)) {
            if (id !== chatId) this.dropdownOpenStates[id] = false
        }

        this.dropdownOpenStates[chatId] = !this.dropdownOpenStates[chatId]
    }

    selectChatAction(chat: ChatRoom, action: keyof typeof MenusMap) {
        this.toggleDropdown(chat.id)

        if (action === MenusMap.Delete) {
            this.deleteChat(chat)
            return
        }

        this.toggleArchiveChat(chat)
    }

    toggleArchiveChat(chat: ChatRoom) {
        this.alertService
            .confirm(
                chat.isArchive ? 'Confirm unarchive' : 'Confirm Archive',
                chat.isArchive
                    ? 'Are you sure you want to unarchive this chat?'
                    : 'Are you sure you want to archive this chat?',
            )
            .subscribe((result) => {
                result && this.chatService.toggleArchiveChat(chat)
            })
    }

    deleteChat(chat: ChatRoom) {
        this.alertService
            .confirm('Confirm Delete', 'Are you sure you want to delete this chat?')
            .subscribe((result) => {
                if (result) {
                    const chatType = chat.isGroupChat ? 'group' : 'private'
                    this.chatService.deleteChat(chat.id, chatType)
                }
            })
    }
}
