import { AsyncPipe } from '@angular/common'
import { Component, Injector, OnInit, inject, signal } from '@angular/core'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { ChatRoom } from '@modules/chat/models/chat.model'
import { ChatService } from '@modules/chat/services/chat.service'
import { TuiButtonModule, TuiDialogService } from '@taiga-ui/core'
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus'
import { AddChatDialogComponent } from '../add-chat-dialog/add-chat-dialog.component'
import { ChatroomComponent } from '../chatroom/chatroom.component'

@Component({
    selector: 'app-chat-list',
    standalone: true,
    templateUrl: './chat-list.component.html',
    styleUrl: './chat-list.component.scss',
    imports: [AsyncPipe, TuiButtonModule, ChatroomComponent],
})
export class ChatListComponent {
    private readonly dialogs = inject(TuiDialogService)
    private readonly injector = inject(Injector)
    chatService = inject(ChatService)
    authStateService = inject(AuthStateService)

    showArchivedChats = signal<boolean>(false)

    openAddGroupChatModal() {
        this.dialogs
            .open<ChatRoom>(new PolymorpheusComponent(AddChatDialogComponent, this.injector), {
                size: 'auto',
                data: null,
                dismissible: true,
                label: 'Create Chat',
            })
            .subscribe({
                next: (result) => {
                    if (result) {
                        this.chatService.pushRoom(result)
                        this.chatService.switchRoom(result)
                    }
                },
            })
    }

    loadMyArchivedChats() {
        this.showArchivedChats.set(!this.showArchivedChats())

        if (this.showArchivedChats()) this.chatService.loadMyArchivedChats()
    }
}
