import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { TaigaUiImports } from '@core/ui/taiga-ui-imports'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { ChatRoom } from '@modules/chat/models/chat.model'
import { ChatApiService } from '@modules/chat/services/chat-api.service'
import { User } from '@modules/user/model/user.interface'
import { UserListStateService } from '@modules/user/states/user-list-state.service'
import { TuiContextWithImplicit, TuiIdentityMatcher, TuiStringHandler } from '@taiga-ui/cdk'
import { TuiDialogContext } from '@taiga-ui/core'
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus'
import { intersects, isEqual, unique } from 'radash'
import { UserDropdownComponent } from '../../../user/component/user-dropdown/user-dropdown.component'

@Component({
    selector: 'app-add-chat-dialog',
    standalone: true,
    templateUrl: './add-chat-dialog.component.html',
    styleUrls: ['./add-chat-dialog.component.scss'],
    imports: [...TaigaUiImports, ReactiveFormsModule, CommonModule, UserDropdownComponent],
})
export class AddChatDialogComponent {
    private readonly context = inject<TuiDialogContext<ChatRoom, ChatRoom>>(POLYMORPHEUS_CONTEXT)

    userListStateService = inject(UserListStateService)
    chatApiService = inject(ChatApiService)
    authStateService = inject(AuthStateService)

    form = new FormGroup({
        name: new FormControl('', Validators.required),
        participantIds: new FormControl<string[]>([], Validators.minLength(2)),
    })
    selectionControl = new FormControl('All')
    selectedUsersControl = new FormControl<User[]>([])
    oneOnOneUserControl = new FormControl<string>(null)

    readonly userStringify: TuiStringHandler<User | TuiContextWithImplicit<User>> = (item) =>
        'firstName' in item && 'lastName' in item
            ? `${item.firstName} ${item.lastName}`
            : `${item.$implicit.firstName} ${item.$implicit.lastName}`

    readonly identityMatcher: TuiIdentityMatcher<User> = (a, b) => a.id === b.id

    get chatRoom(): ChatRoom | null {
        return this.context.data
    }

    get isEdit() {
        return !!this.chatRoom
    }

    ngOnInit(): void {
        this.selectedUsersControl.valueChanges.subscribe({
            next: (value) => {
                this.form.get('participantIds').setValue(value.map((user) => user.id))
            },
        })
        this.checkNameControlOnSelectionChange()
        this.isEdit && this.patchForm()
    }

    onSearchChange(search: string) {
        this.userListStateService.setState({ searchTerm: search })
    }

    saveChatRoom() {
        let stream = undefined
        if (this.selectionControl.value === 'OneOnOne') {
            stream = this.savePrivateChatRoom()
        } else if (this.selectionControl.value === 'Selected') {
            stream = this.saveGroupChatRoom()
        } else {
            this.form
                .get('participantIds')
                .setValue(this.userListStateService.getState().loadedUsers.map((u) => u.id))
            stream = this.saveGroupChatRoom()
        }

        stream.subscribe({
            next: ({ data }) => {
                this.context.completeWith(data)
            },
        })
    }

    private savePrivateChatRoom() {
        return this.chatApiService.createOrGetPrivateChat(this.oneOnOneUserControl.value)
    }

    private checkNameControlOnSelectionChange() {
        this.selectionControl.valueChanges.subscribe({
            next: (value) => {
                const nameControl = this.form.get('name')

                if (value === 'OneOnOne') {
                    nameControl.clearValidators()
                } else {
                    nameControl.setValidators(Validators.required)
                }

                nameControl.updateValueAndValidity()
            },
        })
    }

    private saveGroupChatRoom() {
        if (this.chatRoom) {
            return this.chatApiService.updateGroupChat(
                this.chatRoom.id,
                this.form.value.name,
                this.form.value.participantIds,
            )
        }
        return this.chatApiService.createGroupChat(
            this.form.value.name,
            this.form.value.participantIds,
        )
    }

    private patchForm() {
        this.form.patchValue(this.chatRoom)

        const isAll = isEqual(
            unique(this.chatRoom.participants.map((x) => x.id)).length,
            unique(this.userListStateService.getState().loadedUsers.map((x) => x.id)).length,
        )

        if (this.chatRoom.participants.length === 2 && !this.chatRoom.isGroupChat) {
            this.selectionControl.setValue('OneOnOne')
            const otherUser = this.chatRoom.participants.find(
                (user) => user.id !== this.authStateService.getState().user.id,
            )
            this.oneOnOneUserControl.setValue(otherUser.id)
        } else if (isAll) {
            this.selectionControl.setValue('All')
        } else {
            this.selectionControl.setValue('Selected')
        }
        this.selectedUsersControl.setValue(this.chatRoom.participants)
    }
}
